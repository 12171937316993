import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "create--add-events"
    }}>{`Create & Add Events`}</h1>
    <p>{`Let's explore how to create and add a new event to the Events Calendar.`}</p>
    <h2 {...{
      "id": "create-event"
    }}>{`Create Event`}</h2>
    <ol>
      <li parentName="ol">{`Click 'Add Event' from the left-hand-side menu in the staff view`}</li>
      <li parentName="ol">{`Enter all event details as they would appear to the public (all fields with the * are compulsory, all others are optional)`}</li>
      <li parentName="ol">{`Add 'Event Name' - what the event or series is called`}</li>
      <li parentName="ol">{`Add 'Series Name' - if the event is part of a series`}</li>
      <li parentName="ol">{`Add 'Event Description' - try to make this as descriptive as possible but limit to 500 characters`}</li>
      <li parentName="ol">{`Optionally add 'Event Requirements' - anything that your attendees will need to have or bring, including skill level requirements for advanced events`}</li>
      <li parentName="ol">{`Optionally add 'Sponsor Information'- add any relevant partnership information (add a partner logo when event is pending or after it has been approved)`}</li>
      <li parentName="ol">{`Type or Select 'Event Date' and 'Event Time'`}</li>
      <li parentName="ol">{`Select 'Event Duration' e.g. 0.5 hours is 30 minutes`}</li>
      <li parentName="ol">{`Optionally add 'Bookings Open' - if you only want bookings to open a certain number of days before the event e.g. 7 would mean the event will become bookable 7 days prior to the event`}</li>
      <li parentName="ol">{`Select 'Once Off' or to 'Recur at Intervals' or 'Recur Monthly by Day' or 'Recur Monthly by Week'`}</li>
      <li parentName="ol">{`Select frequency 'once off' or 'recurring', and select the number of times you want the event to run under instances`}</li>
      <li parentName="ol">{`Click the blue magic wand, if recurring with multiple instances, to see the matching dates - these can be edited within the box using the same format`}</li>
      <li parentName="ol">{`Select 'Event Location'`}</li>
      <li parentName="ol">{`Choose one or more options from the 'Event Category' selection`}</li>
      <li parentName="ol">{`Select whether you would like this event to be private. Private events do not appear on the events homepage, and are instead accessible by direct link.`}</li>
      <li parentName="ol">{`Add 'Event Cost'- if the event is free leave blank or add the cost figure`}</li>
      <li parentName="ol">{`Add number of 'Bookings' - leave blank if bookings are not required`}</li>
      <li parentName="ol">{`Add 'Age Min' and 'Age Max' if the event has age restrictions - leave blank if there are no restrictions or for adult events simply add 18 in the 'Age Min' requirement to display the word Adult.`}</li>
      <li parentName="ol">{`All online events have the option of adding a URL. This will be accessible to anyone who views that event`}</li>
      <li parentName="ol">{`Add the staff 'Contact Person Email Address' - this is only for staff use and not visible to the public`}</li>
      <li parentName="ol">{`'Request Flyer' - select Yes or No to request a flyer for the event from your marketing or promotions team`}</li>
      <li parentName="ol">{`Add 'Event Image'- Either select an image from the computer, or alternatively you can select the blue camera which links to pexels.com (The minimum image size is 1000px by 500px and we suggest landscape images)`}</li>
      <li parentName="ol">{`Once you have either downloaded an image or selected one, click choose file and click on the image`}</li>
      <li parentName="ol">{`Preview the image by clicking on the blue image icon and delete image by clicking on the red x`}</li>
      <li parentName="ol">{`Optionally add an 'Event Note'- this can only be seen by staff`}</li>
      <li parentName="ol">{`Click 'Submit Event' for your event to be added to pending approval for the events calendar`}</li>
    </ol>
    <h2 {...{
      "id": "save-an-event-as-template-or-draft"
    }}>{`Save an Event as Template or Draft`}</h2>
    <p>{`Save a template or a draft to finish later. Templates and drafts are visible to all staff.`}</p>
    <ol>
      <li parentName="ol">{`Create an Event`}</li>
      <li parentName="ol">{`Enter all event details`}</li>
      <li parentName="ol">{`Click 'Save As Template', or ' Save As draft'`}</li>
    </ol>
    <h2 {...{
      "id": "load-saved-event"
    }}>{`Load Saved Event`}</h2>
    <p>{`Load and finish a draft or work from a template`}</p>
    <ol>
      <li parentName="ol">{`Click 'Add Event' from the left-hand-side menu in the staff view`}</li>
      <li parentName="ol">{`Click Load Saved Event to load from an event template or a saved draft`}</li>
      <li parentName="ol">{`Select the Template or Saved Draft from the list`}</li>
      <li parentName="ol">{`Enter any additional event details (all fields with the * are compulsory and all others are optional)`}</li>
      <li parentName="ol">{`Click 'Submit Event' for your event to be added to pending approval for the events calendar`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/aG1tOm4rfco" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">Some functions are only available once an event is pending review. This includes the ability to select an event as Featured on the calendar's homepage.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      